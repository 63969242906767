import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { useAuth } from '../../providers/auth/auth.context';
import { Avatar, Box, Drawer, IconButton, Link } from '@mui/material';
import { HeaderMenu, UserLoggedIn } from './Layout.styles';
import { useNavigate } from 'react-router-dom';
import { marketplaceUrl, twojtrenerClientUrl } from '../../config/config';
import styled from '@emotion/styled';
import ActionsButton, { ActionItem } from '../ActionsButton/ActionsButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Color } from '../../styles/colors';

const Header = () => {
  const navigate = useNavigate();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { user, token, onLogout } = useAuth();

  const actions: ActionItem[] = [
    {
      id: 'my-profile',
      label: 'Moje treningi',
      onClick: () => navigate('/events'),
    },
    {
      id: 'finished-events',
      label: 'Zakończone treningi',
      onClick: () => navigate('/events/finished'),
    },
    {
      id: 'logout',
      label: 'Wyloguj się',
      onClick: () => onLogout(true),
    },
  ];

  const getMenuItems = () => {
    const items = [
      { href: `${marketplaceUrl}/trenerzy`, label: 'Trenerzy' },
      { href: `${marketplaceUrl}/fizjoterapeuci`, label: 'Fizjoterapeuci' },
      { href: `${marketplaceUrl}/obiekty/warszawa`, label: 'Mapa obiektów' },
      { href: `${marketplaceUrl}/blog`, label: 'Blog' },
    ];

    if (user?.type === 100) {
      items.push({ href: `${marketplaceUrl}/admin/trainers`, label: 'ADMIN' });
    }

    if (!token) {
      items.push({
        href: `${twojtrenerClientUrl}/login`,
        label: 'Zaloguj się',
      });
    }

    return items;
  };

  return (
    <HeaderWrapper>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Box component={'div'}>
          <a href={marketplaceUrl}>
            <img
              src={'/img/img_logo_twojtrener.png'}
              height={30}
              alt={'twojtrener.pl'}
            />
          </a>
        </Box>

        <HeaderMenu>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
            {getMenuItems().map((item) => (
              <Link key={item.label} href={item.href}>
                {item.label}
              </Link>
            ))}
          </Box>
          {token && user && (
            <ActionsButton actions={actions} isIcon={false}>
              <UserLoggedIn>
                <Avatar sx={{ width: 30, height: 30 }} />
                <ExpandMoreIcon />
              </UserLoggedIn>
            </ActionsButton>
          )}
          <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
            <IconButton onClick={() => setIsMenuOpened(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor={'right'}
              open={isMenuOpened}
              onClose={() => setIsMenuOpened(false)}
            >
              <List sx={{ width: '250px' }}>
                <ListItem sx={{ justifyContent: 'right' }}>
                  <IconButton onClick={() => setIsMenuOpened(false)}>
                    <CloseIcon />
                  </IconButton>
                </ListItem>
                {getMenuItems().map((item) => (
                  <ListItem disablePadding key={item.label}>
                    <ListItemButton
                      onClick={() => (window.location.href = item.href)}
                    >
                      {item.label}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Box>
        </HeaderMenu>
      </Toolbar>
    </HeaderWrapper>
  );
};
export default Header;

const HeaderWrapper = styled('header')({
  borderBottom: `solid 1px ${Color.veryLightGrey}`,
  display: 'block',
  width: '100%',
  backgroundColor: Color.white,
});
