import React, { useReducer, useState } from 'react';
import { NotificationContext } from './notification.context';
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { initialMessage, Message, MessageType } from '../../types/Message';

const infoReducerInit = { loadingsCount: 0 };
const infoReducer = (
  state: { loadingsCount: number },
  action: { type: string }
) => {
  switch (action.type) {
    case 'increment':
      return { loadingsCount: state.loadingsCount + 1 };
    case 'decrement':
      return { loadingsCount: state.loadingsCount - 1 };
    default:
      throw new Error();
  }
};

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [message, setMessage] = useState<Message>(initialMessage);
  const [state, dispatch] = useReducer(infoReducer, infoReducerInit);

  const showMessage = (content: string, type?: MessageType) => {
    if (!content || !type) {
      closeMessage();
    }
    setMessage({
      visible: true,
      content,
      type: type || 'success',
    });
  };

  const closeMessage = () => {
    setMessage({ ...message, visible: false });
  };

  const setLoading = (show: boolean) => {
    dispatch({ type: show ? 'increment' : 'decrement' });
  };

  const value = {
    showMessage,
    setLoading,
    isLoading: state.loadingsCount > 0,
  };

  return (
    <>
      <NotificationContext.Provider value={value}>
        <>{children}</>
        <Snackbar
          open={message.visible}
          onClose={() => closeMessage()}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity={message.type}>{message.content}</Alert>
        </Snackbar>
        <Backdrop open={state.loadingsCount > 0} sx={{ color: '#fff' }}>
          <CircularProgress color={'inherit'} />
        </Backdrop>
      </NotificationContext.Provider>
    </>
  );
};
export default NotificationProvider;
