import React from 'react';
import { PublicTrainer } from '../../types/PublicTrainer';
import { Avatar, Button, Card, CardContent } from '@mui/material';
import { marketplaceUrl, twojtrenerClientUrl } from '../../config/config';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MessageIcon from '@mui/icons-material/Message';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useModal } from '../../providers/modal/modal.context';

const TrainerCard = ({
  trainer,
  disableCalendarButton,
}: {
  trainer: PublicTrainer;
  disableCalendarButton?: boolean;
}) => {
  const { openModal } = useModal();

  const onOpenMessageModal = () => {
    openModal({
      type: 'message_modal',
      params: {
        trainer,
      },
      onCancel: (isChange: boolean) => false,
    });
  };

  return (
    <>
      <Card sx={{ marginBottom: '20px' }}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            h3: {
              textAlign: 'center',
            },
          }}
        >
          <Avatar
            sx={{ width: 60, height: 60, marginBottom: '10px' }}
            src={
              trainer?.avatar
                ? `${marketplaceUrl}/content/trainer_${trainer?.avatar}`
                : undefined
            }
          />
          <h3 className="mb10">{trainer?.name}</h3>

          <Button
            href={`${marketplaceUrl}/trener/${trainer?.slug}`}
            variant={'outlined'}
            size={'small'}
            startIcon={<AccountCircleIcon />}
            className="mb10 btn-r"
          >
            Profil trenera
          </Button>

          <Button
            startIcon={<MessageIcon />}
            variant={'outlined'}
            size={'small'}
            onClick={() => onOpenMessageModal()}
            className="mb10 btn-r"
          >
            Wyślij wiadomość
          </Button>

          {!disableCalendarButton && (
            <Button
              href={`${twojtrenerClientUrl}/t/${trainer?.slug}`}
              variant={'outlined'}
              size={'small'}
              startIcon={<CalendarMonthIcon />}
              className="btn-r"
            >
              Kalendarz trenera
            </Button>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default TrainerCard;
