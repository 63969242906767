import React from 'react';
import { Box, Link } from '@mui/material';
import { marketplaceUrl } from '../../config/config';

const ErrorPage = ({
  code = 404,
  withLogo = true,
}: {
  code?: number;
  withLogo?: boolean;
}) => {
  return (
    <Box sx={{ p: 5, textAlign: 'center' }}>
      {withLogo && (
        <>
          <Link href={marketplaceUrl}>
            <img
              src={'/img/img_logo_twojtrener.png'}
              height={40}
              alt={'twojtrener.pl'}
            />
          </Link>
          <br />
          <br />
        </>
      )}
      <h1>{code}</h1>
      <br />
      <h3>Nie znaleziono strony</h3>
      <Box sx={{ pt: 5, mb: 5 }}>
        <Link href={marketplaceUrl}>Wróć do strony głównej</Link>
      </Box>
    </Box>
  );
};
export default ErrorPage;
