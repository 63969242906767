import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface ActionItem {
  id: string;
  label: string;
  onClick?: () => void;
}

const ActionsButton = ({
  children,
  actions = [],
  isIcon = true,
}: {
  children: React.ReactNode;
  actions: ActionItem[];
  isIcon?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (actions.length === 0) {
    return <></>;
  }

  return (
    <Box>
      {isIcon && (
        <IconButton color={'primary'} size={'small'} onClick={handleClick}>
          {children ? children : <MoreVertIcon />}
        </IconButton>
      )}
      {!isIcon && <Box onClick={(ev) => handleClick(ev)}>{children}</Box>}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {actions.map((item: ActionItem) => {
          return (
            <MenuItem
              key={item.id}
              onClick={(ev) => {
                item.onClick && item.onClick();
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
export default ActionsButton;
