import { useEffect, useState } from 'react';
import http from '../api/http';
import { useNotification } from '../providers/notification/notification.context';
import { PublicTrainer } from '../types/PublicTrainer';

const useTrainer = (trainerSlug?: string) => {
  const [trainer, setTrainer] = useState<PublicTrainer>();
  const { setLoading, showMessage } = useNotification();

  useEffect(() => {
    if (trainerSlug) {
      loadTrainer(trainerSlug);
    }
  }, [trainerSlug]);

  const loadTrainer = (trainerSlug: string) => {
    setLoading(true);
    http()
      .get(`/trainer/${trainerSlug}/`)
      .then(({ data: trainerData }) => {
        setTrainer(trainerData);
      })
      .catch(() => {
        showMessage('Nie można załadować danych trenera', 'error');
      })
      .finally(() => setLoading(false));
  };

  return trainer;
};
export default useTrainer;
