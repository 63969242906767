import moment from 'moment';
import { displayDateFormat } from './config/config';
import { EventStatus } from './types/Training';
import { FormError } from './types/FormError';

export const flatData = (data: any) => JSON.parse(JSON.stringify(data));

export const getEventStatusLabel = (status: EventStatus) => {
  if (status === 'not_started') {
    return 'nie rozpoczął się';
  } else if (status === 'in_progress') {
    return 'w trakcie trwania';
  } else if (status === 'finished') {
    return 'zakończony';
  }
  return '';
};

export const displayDate = (date?: string | number) => {
  if (!date) {
    return '';
  }
  return typeof date === 'number'
    ? moment.unix(date).format(displayDateFormat)
    : moment(date).format(displayDateFormat);
};

export const displayDayMonth = (date?: string | number) => {
  if (!date) {
    return '';
  }
  return typeof date === 'number'
    ? moment.unix(date).format('DD. MMM')
    : moment(date).format('DD. MMM');
};

export const displayWeekDay = (date?: string | number) => {
  if (!date) {
    return '';
  }
  const mDate = typeof date === 'number' ? moment.unix(date) : moment(date);

  if (mDate.isSame(moment(), 'day')) {
    return 'dziś';
  } else if (mDate.isSame(moment().add(1, 'day'), 'day')) {
    return 'jutro';
  }
  return firstLetterUppercase(mDate.locale('pl').format('ddd'));
};

export const displayTime = (timestamp?: number) => {
  if (!timestamp) {
    return '';
  }
  return moment.unix(timestamp).format('HH:mm');
};

export const firstLetterUppercase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getApiValidationErrors = (errorsResponse: any) => {
  const errObject: any = {};
  if (
    errorsResponse &&
    errorsResponse.response &&
    errorsResponse.response.data &&
    Array.isArray(errorsResponse.response.data.errors)
  ) {
    const errorsList = errorsResponse.response.data.errors;
    for (let i = 0; i < errorsList.length; i++) {
      if (!errObject[errorsList[i].param]) {
        errObject[errorsList[i].param] = errorsList[i].msg;
      }
    }
  }
  return errObject;
};

export const apiErrorsAsFormErrors = (errorsResponse: any): FormError[] => {
  if (
    errorsResponse?.response?.data?.errors &&
    Array.isArray(errorsResponse.response.data.errors)
  ) {
    return errorsResponse.response.data.errors.map((errObj: any) => ({
      key: errObj.param,
      message: errObj.msg,
    }));
  }
  return [];
};
