import React, { useState } from 'react';
import { Box, Container, CssBaseline } from '@mui/material';
import Header from './Header';
import { Color } from '../../styles/colors';
import { LayoutBody, LayoutFooter } from './Layout.styles';
import { marketplaceUrl } from '../../config/config';

const Layout = ({
  children,
}: {
  variant?: string;
  children: React.ReactNode;
}) => {
  return (
    <LayoutBody>
      <CssBaseline />
      <Header />
      <Container sx={{ paddingTop: '20px' }}>{children}</Container>
      <LayoutFooter>
        <a href={marketplaceUrl}>
          <img
            src={'/img/img_logo_twojtrener_white.png'}
            height={20}
            alt={'twojtrener.pl'}
          />
        </a>
      </LayoutFooter>
    </LayoutBody>
  );
};
export default Layout;
