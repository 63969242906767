import React, { useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { Alert, Box, Button, Grid } from '@mui/material';
import Layout from '../../components/Layout/Layout';
import 'moment/locale/pl';
import { displayDate, displayTime, getEventStatusLabel } from '../../utils';
import { PublicTrainer } from '../../types/PublicTrainer';
import { PublicEvent } from '../../types/PublicEvent';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import { useAuth } from '../../providers/auth/auth.context';
import useCreateBooking from '../../hooks/use-create-booking';
import { InfoBox, PageHeader } from '../../styles/general';
import ErrorPage from '../ErrorPage/ErrorPage';
import useTrainer from '../../hooks/use-trainer';
import { Service } from '../../types/Service.type';
import useService from '../../hooks/use-service';

const EventServicePage = () => {
  const params = useParams();
  const [event, setEvent] = useState<PublicEvent>();
  const [isApiError, setIsApiError] = useState(false);
  const { token } = useAuth();
  const createBooking = useCreateBooking();
  const navigate = useNavigate();
  const location = useLocation();
  const trainer: PublicTrainer | undefined = useTrainer(params.trainerSlug);
  const service: Service | undefined = useService(
    params.trainerSlug,
    params.serviceUid
  );

  const onLoginRedirect = () => {
    navigate(`/login?next=${location.pathname}${location.search}`);
  };

  if (isApiError) {
    return (
      <Layout>
        <ErrorPage withLogo={false} />
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeader>{service?.name}</PageHeader>

      {trainer && (
        <Box className="mb20">
          <Link to={`/t/${trainer.slug}`}>Wróć do kalendarza trenera</Link>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Grid container spacing={2} className="mb20">
            <Grid item xs={12} sm={6}>
              <InfoBox>
                <CalendarMonthIcon fontSize={'small'} />{' '}
                <span>
                  Kiedy: {displayDate(params?.startDate)} {params?.startTime}
                </span>
              </InfoBox>
              <InfoBox>
                <AccessTimeIcon fontSize={'small'} />{' '}
                <span>Czas trwania: {service?.time} min.</span>
              </InfoBox>
              <InfoBox>
                <AttachMoneyIcon fontSize={'small'} />{' '}
                <span>Cena: {service?.price} zł</span>
              </InfoBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoBox>
                <LocationOnIcon fontSize={'small'} />
                <span>Gdzie: {service?.EventLocation?.name}</span>
              </InfoBox>
              <InfoBox>
                <PersonIcon fontSize={'small'} />
                <span>Zajęcia indywidualne</span>
              </InfoBox>
            </Grid>
          </Grid>

          <Box className="mb20">
            {token && service?.uid && (
              <Button
                size={'medium'}
                variant={'contained'}
                onClick={() => {
                  // TODO:
                }}
                startIcon={<EventAvailableIcon />}
                className="btn-r"
              >
                Zapisz się na zajęcia
              </Button>
            )}
            {!token && service && (
              <Button
                size={'small'}
                variant={'outlined'}
                onClick={() => onLoginRedirect()}
                className="btn-r"
              >
                Zaloguj się, aby się zapisać
              </Button>
            )}
          </Box>

          <p>{event?.description}</p>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3}>
          {trainer && <TrainerCard trainer={trainer} />}
        </Grid>
      </Grid>
    </Layout>
  );
};
export default EventServicePage;
