import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ConfirmEmailPage from './RegisterPage/ConfirmEmailPage';
import RegisterPage from './RegisterPage/RegisterPage';
import LoginPage from './LoginPage/LoginPage';
import ErrorPage from './ErrorPage/ErrorPage';
import TrainerCalendar from './TrainerPage/TrainerCalendar';
import HomePage from './HomePage/HomePage';
import ProtectedRoute from '../providers/auth/ProtectedRoute';
import EventsPage from './Events/EventsPage';
import CancelEventPage from './Events/CancelEventPage';
import EventPage from './EventPage/EventPage';
import FacebookLoginPage from './LoginPage/FacebookLoginPage';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import EventServicePage from './EventServicePage/EventServicePage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route path="register">
        <Route path="confirm-email/:hash" element={<ConfirmEmailPage />} />
        <Route index element={<RegisterPage />} />
      </Route>
      <Route path="login/facebook" element={<FacebookLoginPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route
        path="events/cancel/:eventClientId/:eventClientHash"
        element={<CancelEventPage />}
      />
      <Route path="events/:status" element={<EventsPage />} />
      <Route path="events" element={<EventsPage />} />
      <Route path={'e/:eventSlug/:eventUid'} element={<EventPage />} />
      <Route
        path={'booking/:trainerSlug/:serviceUid/:startDate/:startTime'}
        element={<EventServicePage />}
      />
      <Route path={'t/:trainerSlug'} element={<TrainerCalendar />} />
      <Route path="/404" element={<ErrorPage code={404} />} />
      <Route path="/500" element={<ErrorPage code={500} />} />
      <Route path="*" element={<ErrorPage code={404} />} />
    </Routes>
  );
};
export default AppRoutes;
