import styled from '@emotion/styled';
import { Button } from '@mui/material';
import React from 'react';

export const ButtonPrev = styled(Button)({
  borderRadius: '100px',
  minWidth: 0,
  padding: 0,
});

export const ButtonNext = styled(Button)({
  borderRadius: '100px',
  minWidth: 0,
  padding: 0,
});
