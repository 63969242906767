import http from '../api/http';
import { useNotification } from '../providers/notification/notification.context';

const useCreateBooking = () => {
  const { setLoading, showMessage } = useNotification();

  return (eventUid: string, onCancel?: (reload: boolean) => void) => {
    if (
      window.confirm('Czy na pewno chcesz się zapisać na wybrane wydarzenie?')
    ) {
      setLoading(true);
      http()
        .post(`/client/bookings/${eventUid}`)
        .then(({ data }) => {
          if (data?.status === 'err' && data?.msg) {
            showMessage(data.msg, 'error');
            onCancel && onCancel(false);
          } else if (data?.status === 'ok' && data?.msg) {
            showMessage(data.msg, 'success');
            onCancel && onCancel(true);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            showMessage(err?.response?.data?.message, 'error');
          } else {
            showMessage('Wystąpił błąd, proszę spróbować ponownie...', 'error');
          }
        })
        .finally(() => setLoading(false));
    }
  };
};
export default useCreateBooking;
