import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../providers/auth/auth.context';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
} from '@mui/material';
import Layout from '../../components/Layout/Layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FBLoginRedirectUri, marketplaceUrl } from '../../config/config';
import { useNotification } from '../../providers/notification/notification.context';
import http from '../../api/http';
import ClientForm, {
  ClientFormData,
} from '../../components/ClientForm/ClientForm';
import { apiErrorsAsFormErrors, getApiValidationErrors } from '../../utils';
import { FormError } from '../../types/FormError';

const FacebookLoginPage = () => {
  const [formErrors, setFormErrors] = useState<any>({});
  const [clientFormErrors, setClientFormErrors] = useState<FormError[]>([]);
  const [fbUserData, setFbUserData] = useState<ClientFormData>();
  const [fbEmail, setFbEmail] = useState('');
  const [fbAccessToken, setFbAccessToken] = useState('');
  const [isFbRegister, setIsFbRegister] = useState(false);
  const { handleToken } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading, showMessage } = useNotification();
  const navigate = useNavigate();
  const termsRef = useRef<any>();

  useEffect(() => {
    if (searchParams.get('code')) {
      onFacebookLogin(searchParams.get('code') || '');
    } else {
      navigate('/login');
    }
  }, [searchParams]);

  const onFacebookLogin = (code: string) => {
    if (!code) {
      return false;
    }
    setLoading(true);
    http()
      .post('/client/auth/login/facebook', {
        code,
        redirectUri: FBLoginRedirectUri,
      })
      .then(({ data }) => {
        if (data?.status && data.status === 'ok' && data?.token) {
          showMessage('Zalogowano poprawnie', 'success');
          handleToken(data.token, '/');
        } else {
          setFbUserData(data);
          setFbEmail(data.email);
          setFbAccessToken(data.accessToken);
          setIsFbRegister(!!(data && data.email));
        }
      })
      .catch(() => {
        showMessage(
          'Wystąpił błąd komunikacji z serwisem Facebook, spróbuj ponownie za chwilę',
          'error'
        );
        navigate('/login');
      })
      .finally(() => setLoading(false));
  };

  const onRegisterClick = () => {
    const terms = termsRef.current.checked;
    setLoading(true);
    http()
      .post('/client/auth/register/facebook', {
        ...fbUserData,
        email: fbEmail,
        terms,
        fbAccessToken,
      })
      .then(({ data }) => {
        if (data?.token) {
          showMessage('Zalogowano poprawnie', 'success');
          handleToken(data.token);
        } else {
          showMessage('Błąd logowania, spróbuj ponownie za chwilę', 'error');
        }
      })
      .catch((errors) => {
        setFormErrors(getApiValidationErrors(errors));
        setClientFormErrors(apiErrorsAsFormErrors(errors));
        if (errors?.response?.data?.status === 'err') {
          showMessage('Popraw błędy w formularzu i spróbuj ponownie', 'error');
        } else {
          showMessage(
            'Wystąpił błąd komunikacji z serwisem Facebook, spróbuj ponownie za chwilę',
            'error'
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const onChange = (data: ClientFormData) => setFbUserData(data);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12} sx={{ margin: '0 auto' }}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
            Zaloguj się jako sportowiec
          </h2>

          {isFbRegister ? (
            <>
              <div className="mb20">
                <ClientForm
                  validationTrigger={1}
                  formErrors={clientFormErrors}
                  defaultData={fbUserData}
                  onChange={(data) => onChange(data)}
                  isEmailDisabled={true}
                />
              </div>
              <Box sx={{ mb: 4 }}>
                <FormLabel
                  error={!!formErrors?.terms}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Checkbox inputRef={termsRef} />
                  <span>
                    Akceptuję{' '}
                    <Link
                      href={`${marketplaceUrl}/regulamin`}
                      data-testid={'linkTerms'}
                      target="_blank"
                    >
                      Regulamin
                    </Link>{' '}
                    oraz{' '}
                    <Link
                      href={`${marketplaceUrl}/polityka-prywatnosci`}
                      data-testid={'linkPolicy'}
                      target="_blank"
                    >
                      Politykę prywatności i plików cookies
                    </Link>
                  </span>
                </FormLabel>
                {formErrors?.terms && (
                  <FormHelperText error={true}>
                    {formErrors.terms}
                  </FormHelperText>
                )}
              </Box>
            </>
          ) : (
            <></>
          )}

          <div className="text-center mb20">
            {isFbRegister ? (
              <Button
                onClick={onRegisterClick}
                variant={'contained'}
                size={'large'}
                type={'submit'}
                data-testid={'btnLogin'}
                className="btn-r"
              >
                Zarejestruj się poprzez Facebook
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};
export default FacebookLoginPage;
