import { useEffect, useState } from 'react';
import http from '../api/http';
import { useNotification } from '../providers/notification/notification.context';
import { Service } from '../types/Service.type';

const useService = (trainerSlug?: string, serviceUid?: string) => {
  const [service, setService] = useState<Service>();
  const { setLoading, showMessage } = useNotification();

  useEffect(() => {
    if (trainerSlug && serviceUid) {
      loadService(trainerSlug, serviceUid);
    }
  }, [trainerSlug, serviceUid]);

  const loadService = (trainerSlug: string, serviceUid: string) => {
    setLoading(true);
    http()
      .get(`/trainer/${trainerSlug}/services/${serviceUid}`)
      .then(({ data: serviceData }) => {
        setService(serviceData);
      })
      .catch(() => {
        showMessage('Nie można załadować danych usługi', 'error');
      })
      .finally(() => setLoading(false));
  };

  return service;
};
export default useService;
