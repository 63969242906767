import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import { DialogActionsStyled } from '../modal.styles';
import { useAuth } from '../../providers/auth/auth.context';
import http from '../../api/http';
import { useNotification } from '../../providers/notification/notification.context';
import { Trainer } from '../../types/Trainer';
import { useForm } from 'react-hook-form';
import ClientForm, {
  ClientFormData,
} from '../../components/ClientForm/ClientForm';
import { getApiValidationErrors } from '../../utils';
import SendIcon from '@mui/icons-material/Send';
import { FormError } from '../../types/FormError';

const MessageModal = ({
  trainer,
  onCancel,
}: {
  trainer: Trainer;
  onCancel: (data?: any) => void;
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [clientFormValidationTrigger, setClientFormValidationTrigger] =
    useState(0);
  const [isClientFormValid, setIsClientFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const { token, user } = useAuth();
  const { setLoading, showMessage } = useNotification();

  useEffect(() => {
    reset({ content: '', firstName: '', lastName: '', email: '', phone: '' });
  }, []);

  const createMessage = (data: any) => {
    setLoading(true);
    setFormErrors([]);
    clearErrors();
    let formData = { ...data };
    if (token) {
      formData = { content: data.content };
    }
    http()
      .post(`/client/messages/${trainer.slug}`, formData)
      .then(() => {
        showMessage('Wiadomość została wysłana', 'success');
        onCancel && onCancel(true);
      })
      .catch((errorResponse) => {
        const errors = getApiValidationErrors(errorResponse);
        const errorsToSet: FormError[] = [];
        Object.keys(errors).forEach((key) => {
          if (key === 'content' && errors.content) {
            setError('content', { message: errors.content });
          }
          errorsToSet.push({ key, message: errors[key] });
        });
        setFormErrors(errorsToSet);
        showMessage('Nie można wysłać wiadomości', 'error');
      })
      .finally(() => setLoading(false));
  };

  const onClientDataChange = (data: ClientFormData, isValid: boolean) => {
    setIsClientFormValid(isValid);
    setValue('firstName', data.firstName);
    setValue('lastName', data.lastName);
    setValue('email', data.email);
    setValue('phone', data.phone);
  };

  const onSubmit = (data: any) => {
    setClientFormValidationTrigger(new Date().getTime());
    if (isClientFormValid || token) {
      createMessage(data);
    }
  };

  const onFormError = (err: any) => {
    setClientFormValidationTrigger(new Date().getTime());
  };

  return (
    <>
      <DialogTitle className="DialogTitlePR">
        Wyślij wiadomość do {trainer.name}
      </DialogTitle>
      <DialogContent>
        <form
          id="new-message-form"
          onSubmit={handleSubmit(onSubmit, onFormError)}
        >
          <Box sx={{ marginBottom: '20px' }}>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel>Treść wiadomości</FormLabel>
              <TextField
                {...register('content', {
                  required: 'Podaj treść wiadomości',
                  minLength: {
                    value: 20,
                    message: 'Wpisz co najmniej 20 znaków',
                  },
                })}
                multiline={true}
                rows={5}
                variant={'outlined'}
                size={'small'}
                error={!!errors.content}
              />
            </FormControl>
            {errors.content && (
              <FormHelperText error={true}>
                <>{errors.content?.message}</>
              </FormHelperText>
            )}
          </Box>
          {token && user ? (
            <Box>
              Wysyłasz wiadomość jako{' '}
              <strong>
                {user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user.email}
              </strong>
            </Box>
          ) : (
            <></>
          )}
          {!token && (
            <ClientForm
              onChange={onClientDataChange}
              formErrors={formErrors}
              validationTrigger={clientFormValidationTrigger}
            />
          )}
        </form>
      </DialogContent>
      <DialogActionsStyled>
        <Button
          type={'submit'}
          variant={'contained'}
          size={'small'}
          form={'new-message-form'}
          className="btn-r"
          startIcon={<SendIcon />}
        >
          Wyślij wiadomość
        </Button>
        <Button
          onClick={() => onCancel()}
          variant={'outlined'}
          size={'small'}
          className="btn-r"
        >
          Zamknij
        </Button>
      </DialogActionsStyled>
    </>
  );
};
export default MessageModal;

const TraininActionButtons = styled('div')({
  padding: '15px 0 0',
});
