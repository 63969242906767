import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment/moment';
import { ButtonNext, ButtonPrev } from '../TrainerCalendar.styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { displayDate, displayDayMonth, displayWeekDay } from '../../../utils';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import http from '../../../api/http';
import { Service } from '../../../types/Service.type';
import { useNotification } from '../../../providers/notification/notification.context';
import { dateFormat } from '../../../config/config';
import { Color } from '../../../styles/colors';
import { useNavigate } from 'react-router-dom';

const daysAmount = 5;

const CalendarSlots = ({ trainerSlug }: { trainerSlug: string }) => {
  const { setLoading, showMessage } = useNotification();
  const navigate = useNavigate();
  const [services, setServices] = useState<Service[]>([]);
  const [selectedServiceId, setSelectedServiceId] = useState<string>('');
  const [slots, setSlots] = useState<any>({});
  const [days, setDays] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>(
    moment().format(dateFormat)
  );
  const [endDate, setEndDate] = useState<string>(
    moment()
      .add(`${daysAmount - 1}`, 'days')
      .format(dateFormat)
  );

  useEffect(() => {
    if (trainerSlug) {
      loadServices();
    }
  }, [trainerSlug]);

  useEffect(() => {
    if (selectedServiceId) {
      loadSlotsForService(selectedServiceId);
    }
  }, [selectedServiceId]);

  useEffect(() => {
    reloadWeekDays();
  }, [startDate, endDate]);

  // TODO move utils
  const reloadWeekDays = () => {
    const newDays = [];
    for (let i = 0; i <= moment(endDate).diff(moment(startDate), 'days'); i++) {
      newDays.push(moment(startDate).add(i, 'days').format(dateFormat));
    }
    setDays(newDays);
    if (selectedServiceId) {
      loadSlotsForService(selectedServiceId);
    }
  };

  const loadServices = () => {
    setLoading(true);
    http()
      .get(`/trainer/${trainerSlug}/services/`)
      .then(({ data }) => {
        setServices(data);
        setSelectedServiceId(data[0]?.uid);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const loadSlotsForService = (serviceUid: string) => {
    setLoading(true);
    http()
      .get(`/trainer/${trainerSlug}/slots/`, {
        params: {
          startDate,
          endDate,
          serviceUid,
        },
      })
      .then(({ data }) => {
        setSlots(data?.slots || {});
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const onPrevDays = () => {
    setStartDate(
      moment(startDate).subtract(daysAmount, 'days').format(dateFormat)
    );
    setEndDate(moment(endDate).subtract(daysAmount, 'days').format(dateFormat));
  };

  const onNextDays = () => {
    setStartDate(moment(startDate).add(daysAmount, 'days').format(dateFormat));
    setEndDate(moment(endDate).add(daysAmount, 'days').format(dateFormat));
  };

  const onSlotBooking = (startDate: string, startTime: string) => {
    navigate(
      `/booking/${trainerSlug}/${selectedServiceId}/${startDate}/${startTime}`
    );
  };

  return (
    <Box>
      <Box>
        <FormLabel>Wybierz trening: </FormLabel>
        <Select
          variant={'outlined'}
          size={'small'}
          value={selectedServiceId}
          onChange={(ev) => {
            setSelectedServiceId(ev.target.value as string);
          }}
        >
          {Array.isArray(services) &&
            services.map((item) => {
              return (
                <MenuItem key={item.uid} value={item.uid}>
                  {item.name}
                </MenuItem>
              );
            })}
        </Select>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <ButtonPrev
                disabled={!moment(startDate).isAfter(moment(), 'day')}
                variant={'contained'}
                color={'primary'}
                size={'small'}
                onClick={() => onPrevDays()}
              >
                <ChevronLeftIcon />
              </ButtonPrev>
            </TableCell>
            {days.map((day) => {
              return (
                <TableCell
                  key={day}
                  sx={{
                    backgroundColor: Color.lightGrey,
                    padding: '10px 15px',
                    fontWeight: 600,
                    textAlign: 'center',
                  }}
                >
                  {displayDayMonth(day)}
                  <br />
                  {displayWeekDay(day)}
                </TableCell>
              );
            })}
            <TableCell>
              <ButtonNext
                variant={'contained'}
                color={'primary'}
                size={'small'}
                onClick={() => onNextDays()}
              >
                <ChevronRightIcon />
              </ButtonNext>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <br />
            </TableCell>
            {days.map((day) => {
              return (
                <TableCell
                  key={day}
                  sx={{ verticalAlign: 'top', textAlign: 'center' }}
                >
                  {slots[day] &&
                    slots[day]?.slots.map((item: any) => {
                      return (
                        <div key={`${day}-${item.startTime}`}>
                          <Chip
                            label={item.startTime}
                            color={'primary'}
                            className="mb10"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => onSlotBooking(day, item.startTime)}
                          />
                        </div>
                      );
                    })}
                </TableCell>
              );
            })}
            <TableCell>
              <br />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
export default CalendarSlots;
