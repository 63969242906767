import React, { useRef, useState } from 'react';
import http from '../../api/http';
import {
  Button,
  FormGroup,
  FormLabel,
  Link,
  TextField,
  Box,
  FormHelperText,
  Grid,
  Checkbox,
} from '@mui/material';
import { useNotification } from '../../providers/notification/notification.context';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { getApiValidationErrors } from '../../utils';
import { marketplaceUrl } from '../../config/config';

const RegisterPage = () => {
  const [formErrors, setFormErrors] = useState<any>({});
  const { showMessage, setLoading } = useNotification();
  const navigate = useNavigate();
  const emailRef = useRef<any>();
  const passwordRef = useRef<any>();
  const firstNameRef = useRef<any>();
  const lastNameRef = useRef<any>();
  const termsRef = useRef<any>();

  const onRegister = () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const terms = termsRef.current.checked;
    setLoading(true);
    http()
      .post('/client/auth/register', {
        email,
        password,
        firstName,
        lastName,
        terms,
      })
      .then(() => {
        showMessage(
          'Sprawdź skrzynkę pocztową, wysłaliśmy tam wiadomość z linkiem aktywacyjnym',
          'success'
        );
        navigate('/login');
      })
      .catch((errors) => {
        setFormErrors(getApiValidationErrors(errors));
        showMessage(
          'Nie można dokończyć rejestracji, popraw błędy i spróbuj ponownie',
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  const onClick = (ev: any) => {
    if (ev.keyCode === 13) {
      onRegister();
    }
  };

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item md={6} sm={8} xs={12} sx={{ margin: '0 auto' }}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
            Dołącz do platformy TwojTrener.pl
            <br />
            jako klient
          </h2>

          <Box className="mb20">
            Aby dokonać rejestracji jako klient wypełnij poniższy formularz. Na
            Twój adres e-mail prześlemy wiadomość z linkiem aktywującym konto.
            Po jego kliknięciu zostaniesz automatycznie zalogowany. Rejestracja
            jest bezpłatna.
          </Box>

          <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ paddingTop: '0 !important' }}
            >
              <FormGroup sx={{ mb: 2 }}>
                <FormLabel>Imię</FormLabel>
                <TextField
                  inputRef={firstNameRef}
                  variant={'outlined'}
                  type={'text'}
                  size={'small'}
                  required={true}
                  onKeyDown={onClick}
                  error={!!formErrors.firstName}
                  inputProps={{ 'data-testid': 'inpFirstName' }}
                />
                {formErrors.firstName && (
                  <FormHelperText error={true}>
                    {formErrors.firstName}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ paddingTop: '0 !important' }}
            >
              <FormGroup sx={{ mb: 2 }}>
                <FormLabel>Nazwisko</FormLabel>
                <TextField
                  inputRef={lastNameRef}
                  variant={'outlined'}
                  type={'text'}
                  size={'small'}
                  required={true}
                  onKeyDown={onClick}
                  error={!!formErrors.lastName}
                  inputProps={{ 'data-testid': 'inpLastName' }}
                />
                {formErrors.lastName && (
                  <FormHelperText error={true}>
                    {formErrors.lastName}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>

          <FormGroup sx={{ mb: 2 }}>
            <FormLabel>Adres e-mail</FormLabel>
            <TextField
              inputRef={emailRef}
              variant={'outlined'}
              type={'email'}
              size={'small'}
              required={true}
              onKeyDown={onClick}
              error={!!formErrors.email}
              inputProps={{ 'data-testid': 'inpEmail' }}
            />
            {formErrors.email && (
              <FormHelperText error={true}>{formErrors.email}</FormHelperText>
            )}
          </FormGroup>

          <FormGroup sx={{ mb: 2 }}>
            <FormLabel>Ustaw hasło</FormLabel>
            <TextField
              inputRef={passwordRef}
              variant={'outlined'}
              size={'small'}
              type={'password'}
              required={true}
              onKeyDown={onClick}
              error={!!formErrors.password}
              inputProps={{ 'data-testid': 'inpPassword' }}
            />
            {formErrors.password && (
              <FormHelperText error={true}>
                {formErrors.password}
              </FormHelperText>
            )}
          </FormGroup>

          <Box sx={{ mb: 4 }}>
            <FormLabel
              error={!!formErrors?.terms}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Checkbox inputRef={termsRef} />
              <span>
                Akceptuję{' '}
                <Link
                  href={`${marketplaceUrl}/regulamin`}
                  data-testid={'linkTerms'}
                  target="_blank"
                >
                  Regulamin
                </Link>{' '}
                oraz{' '}
                <Link
                  href={`${marketplaceUrl}/polityka-prywatnosci`}
                  data-testid={'linkPolicy'}
                  target="_blank"
                >
                  Politykę prywatności i plików cookies
                </Link>
              </span>
            </FormLabel>
            {formErrors?.terms && (
              <FormHelperText error={true}>{formErrors.terms}</FormHelperText>
            )}
          </Box>

          <div className={'text-center'}>
            <Button
              onClick={onRegister}
              variant={'contained'}
              size={'large'}
              data-testid={'btnRegister'}
              className="btn-r"
            >
              Zarejestruj się
            </Button>
          </div>

          <Divider sx={{ mt: 5, mb: 2 }} />

          <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
            Masz już konto?{' '}
            <Link href={'/login'} data-testid={'linkLogin'}>
              Zaloguj się!
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};
export default RegisterPage;
