import React from 'react';
import Layout from '../../components/Layout/Layout';
import { Box, Link } from '@mui/material';
import { marketplaceUrl } from '../../config/config';
import { Link as NLink } from 'react-router-dom';

const HomePage = () => {
  return (
    <Layout>
      <Box sx={{ textAlign: 'center' }}>
        <h2>Witaj w twojtrener.pl!</h2>
        <Box sx={{ mt: 2, mb: 3 }}>
          <span className="fs20">👋</span> Cieszymy się, że jesteś z nami!{' '}
          <span className="fs20">💪</span>
        </Box>
        <Box sx={{ mt: 2, mb: 3 }}>
          Poniżej znajdziesz kilka linków, które będą dla Ciebie przydatne!
        </Box>
        <Box sx={{ mt: 2, mb: 3 }}>
          <NLink to={'/events'}>Twoje treningi</NLink>
          <br />
          <br />
          <Link href={`${marketplaceUrl}/trenerzy`}>Lista trenerów</Link>
          <br />
          <br />
          <Link href={`${marketplaceUrl}/fizjoterapeuci`}>Fizjoterapeuci</Link>
          <br />
          <br />
          <Link href={`${marketplaceUrl}/obiekty/warszawa`}>
            Mapa obiektów w Warszawie
          </Link>
          <br />
          <br />
          <Link href={`${marketplaceUrl}/blog`}>Blog</Link>
        </Box>
      </Box>
    </Layout>
  );
};
export default HomePage;
