import React from 'react';
import AuthProvider from './providers/auth/AuthProvider';
import NotificationProvider from './providers/notification/NotificationProvider';
import AppRoutes from './pages/AppRoutes';
import { createTheme, ThemeProvider } from '@mui/material';
import { Color } from './styles/colors';
import ModalProvider from './providers/modal/ModalProvider';

const ttTheme = createTheme({
  palette: {
    primary: {
      main: Color.ttViolet,
      dark: Color.ttVioletDark,
      light: Color.ttVioletLight,
      contrastText: Color.white,
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={ttTheme}>
      <NotificationProvider>
        <AuthProvider>
          <ModalProvider>
            <AppRoutes />
          </ModalProvider>
        </AuthProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};
export default App;
