import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './auth.context';
import jwt_decode from 'jwt-decode';
import http from '../../api/http';
import Cookies from 'universal-cookie';
import { initialUser, User } from '../../types/User';
import {
  authNextCookieName,
  cookieDomain,
  tokenCookieName,
} from '../../config/config';
import { useNotification } from '../notification/notification.context';

const cookies = new Cookies();

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string>('');
  const [user, setUser] = useState<User>(initialUser);
  const navigate = useNavigate();
  const location = useLocation();
  const { showMessage, setLoading } = useNotification();

  useEffect(() => {
    const token = cookies.get(tokenCookieName);
    if (token) {
      const decoded: any = jwt_decode(token);
      const exp = decoded.exp;
      const current = Math.ceil(new Date().getTime() / 1000);
      if (exp > current) {
        setToken(token);
        setUser(decoded);
        let origin = `${location.pathname}${location.search}` || '/';
        if (
          location.pathname.indexOf('login') >= 0 ||
          location.pathname.indexOf('register') >= 0
        ) {
          origin = '/';
        }
        navigate(origin);
      } else {
        handleLogout(true);
      }
    } else {
      handleLogout();
    }
  }, [navigate, location.pathname, location.search]);

  const handleLogin = async (
    email: string,
    password: string,
    next?: string
  ) => {
    setLoading(true);
    http()
      .post('/auth/login/', { email, password })
      .then(({ data }) => {
        if (data && data.token) {
          handleToken(data.token, next);
        } else {
          handleLogout();
        }
      })
      .catch(() => {
        showMessage('Błąd logowania, spróbuj ponownie', 'error');
        handleLogout();
      })
      .finally(() => setLoading(false));
  };

  const handleToken = (token: string, redirect?: string) => {
    setToken(token);
    setUser(jwt_decode(token) as User);
    cookies.set(tokenCookieName, token, { path: '/', domain: cookieDomain });
    const authNext = cookies.get(authNextCookieName);
    if (authNext) {
      cookies.remove(authNextCookieName, { path: '/', domain: cookieDomain });
      return navigate(authNext);
    }
    const origin = redirect ? redirect : location.state?.from?.pathname || '/';
    navigate(origin);
  };

  const handleLogout = (redirectToLogin = false) => {
    setToken('');
    setUser(initialUser);
    cookies.remove(tokenCookieName, { path: '/', domain: cookieDomain });
    if (redirectToLogin) {
      navigate('/login');
    }
  };

  const value = {
    token,
    user,
    onLogin: handleLogin,
    onLogout: handleLogout,
    handleToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
