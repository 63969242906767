import styled from '@emotion/styled';
import { Color } from './colors';
import { Box } from '@mui/material';

export const Optional = styled('span')({
  color: Color.grey,
  fontSize: 12,
  backgroundColor: Color.lightGrey,
  padding: '2px 5px',
  borderRadius: 4,
  marginLeft: '5px',
});

export const PageHeader = styled('h2')({
  paddingBottom: '10px',
  borderBottom: `solid 1px ${Color.lightGrey}`,
  marginBottom: '20px',
});

export const InfoBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
  span: {
    paddingLeft: '5px',
    fontWeight: 'bold',
  },
});
