import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Link,
} from '@mui/material';
import { useNotification } from '../../providers/notification/notification.context';
import http from '../../api/http';
import { Training } from '../../types/Training';
import { useNavigate, useParams, Link as NLink } from 'react-router-dom';
import { displayDate, getEventStatusLabel } from '../../utils';
import { marketplaceUrl } from '../../config/config';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PageHeader } from '../../styles/general';

const EventsPage = () => {
  const [events, setEvents] = useState<Training[]>([]);
  const { setLoading, showMessage } = useNotification();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    loadEvents(params.status || '');
  }, [params.status]);

  const loadEvents = (status?: string) => {
    setLoading(true);
    http()
      .get(`/client/events/${status}`)
      .then(({ data: eventsData }) => {
        setEvents(eventsData);
      })
      .catch(() => navigate('/404'))
      .finally(() => setLoading(false));
  };

  const onEventCancel = (eventClientId: number) => {
    if (window.confirm('Czy na pewno chcesz anulować swoją obecność?')) {
      setLoading(true);
      http()
        .get(`/client/events/cancel/${eventClientId}`)
        .then(({ data }) => {
          if (data?.status === 'err' && data?.msg) {
            showMessage(data.msg, 'error');
          } else if (data?.status === 'ok' && data?.msg) {
            showMessage(data.msg, 'success');
          }
          loadEvents(params.status || '');
        })
        .catch((err) => {
          showMessage(
            err?.response?.data?.msg || 'Nie można anulować tego wydarzenia',
            'error'
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Layout>
      <Box>
        <PageHeader>
          Moje {params.status ? 'zakończone' : 'przyszłe'} treningi{' '}
          <IconButton onClick={() => loadEvents(params.status || '')}>
            <RefreshIcon />
          </IconButton>
        </PageHeader>

        {events.length === 0 && (
          <>
            <p>Brak treningów do wyświetlenia</p>
            <p>
              <NLink to={'/events/finished'}>Sprawdź zakończone treningi</NLink>
            </p>
          </>
        )}
        {events.length > 0 &&
          events.map((ev) => {
            return (
              <Card key={ev.id} className="mb20" variant={'outlined'}>
                <CardContent>
                  <h4 className="mb20">
                    <span style={{ marginRight: '10px' }}>
                      {displayDate(ev.startDate)} {ev.startTime} - {ev.name}
                    </span>
                    {ev.status &&
                      ['in_progress', 'finished'].includes(ev.status) && (
                        <Chip
                          label={getEventStatusLabel(ev.status)}
                          variant={'filled'}
                          color={ev.status === 'finished' ? 'error' : 'info'}
                          size={'small'}
                        />
                      )}
                  </h4>
                  <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                    <Grid item md={3}>
                      Czas trwania: <strong>{ev.time} min.</strong>
                    </Grid>
                    <Grid item md={3}>
                      Cena:{' '}
                      <strong>
                        {ev.price === 0 ? 'darmowe' : `${ev.price} zł`}
                      </strong>
                    </Grid>
                    <Grid item md={3}>
                      Lokalizacja: <strong>{ev.EventLocation?.name}</strong>
                    </Grid>
                    <Grid item md={3}>
                      Trener:{' '}
                      <Link
                        href={`${marketplaceUrl}/trener/${ev.Trainer?.slug}`}
                      >
                        <strong>{ev.Trainer?.name}</strong>
                      </Link>
                    </Grid>
                    {ev.description && (
                      <Grid item md={12}>
                        Opis: <strong>{ev.description}</strong>
                      </Grid>
                    )}
                  </Grid>

                  {ev.status === 'not_started' && (
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        if (ev.eventClientId) {
                          onEventCancel(ev.eventClientId);
                        }
                      }}
                    >
                      Anuluj trening
                    </Button>
                  )}
                </CardContent>
              </Card>
            );
          })}
      </Box>
    </Layout>
  );
};
export default EventsPage;
