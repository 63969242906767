import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Color } from '../../styles/colors';

export const LayoutBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '100%',
});

export const LayoutFooter = styled('footer')({
  backgroundColor: Color.black,
  display: 'block',
  padding: '20px 30px',
  marginTop: 'auto',
});

export const UserLoggedIn = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const HeaderMenu = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  a: {
    marginRight: '30px',
    fontSize: '16px',
    fontWeight: 600,
    color: Color.menuColor,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: Color.menuHoverColor,
    },
  },
});
