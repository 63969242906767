import React from 'react';
import { Button, Chip, DialogContent, DialogTitle } from '@mui/material';
import { DialogActionsStyled } from '../modal.styles';
import { useAuth } from '../../providers/auth/auth.context';
import { useLocation, useNavigate } from 'react-router-dom';
import { displayDate, displayTime, getEventStatusLabel } from '../../utils';
import useCreateBooking from '../../hooks/use-create-booking';
import { PublicEvent } from '../../types/PublicEvent';
import LanguageIcon from '@mui/icons-material/Language';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const EventViewModal = ({
  event,
  onCancel,
}: {
  event: PublicEvent;
  onCancel: (data?: any) => void;
}) => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const createBooking = useCreateBooking();

  const onLoginRedirect = () => {
    onCancel && onCancel();
    navigate(`/login?next=${location.pathname}${location.search}`);
  };

  const getAvailableParticipantsAmount = () => {
    if (event) {
      return event.maxParticipantsAmount - event.participantsAmount;
    }
    return 0;
  };

  const isPossibleToBook = () => {
    if (event) {
      return (
        getAvailableParticipantsAmount() > 0 && event.status === 'not_started'
      );
    }
    return false;
  };

  return (
    <>
      <DialogTitle className="DialogTitlePR">{event.name}</DialogTitle>
      <DialogContent>
        {event.status && ['in_progress', 'finished'].includes(event.status) && (
          <Chip
            label={`Trening ${getEventStatusLabel(event.status)}`}
            variant={'filled'}
            color={event.status === 'finished' ? 'error' : 'info'}
            size={'small'}
            sx={{ mb: 1 }}
          />
        )}
        <p>
          Kiedy: <strong>{displayDate(event.startDate)}</strong>
        </p>
        <p>
          Czas trwania:{' '}
          <strong>
            {event.startTime} - {displayTime(event.endTimestamp)}
          </strong>
        </p>
        <p>
          Cena: <strong>{event.price} zł</strong>
        </p>
        <p>
          Gdzie: <strong>{event.location}</strong>
        </p>
        <p>
          Typ:{' '}
          <strong>
            {event && event.maxParticipantsAmount > 1 && 'Zajęcia grupowe'}
            {event &&
              event.maxParticipantsAmount === 1 &&
              'Zajęcia indywidualne'}
          </strong>
        </p>
        {isPossibleToBook() && (
          <p>
            Wolnych miejsc: <strong>{getAvailableParticipantsAmount()}</strong>
          </p>
        )}
        {event.description && <p>{event.description}</p>}
      </DialogContent>
      {isPossibleToBook() && (
        <DialogActionsStyled sx={{ paddingBottom: '0 !important' }}>
          {token && (
            <Button
              size={'medium'}
              variant={'contained'}
              onClick={() => {
                createBooking(event.uid, onCancel); //
              }}
              fullWidth
              startIcon={<EventAvailableIcon />}
              className="btn-r"
            >
              Zapisz się
            </Button>
          )}
          {!token && (
            <Button
              size={'small'}
              variant={'outlined'}
              onClick={() => onLoginRedirect()}
              className="btn-r"
            >
              Zaloguj się, aby się zapisać
            </Button>
          )}
        </DialogActionsStyled>
      )}
      <DialogActionsStyled>
        <Button
          onClick={() => {
            navigate(`/e/${event.slug}/${event.uid}`);
            onCancel();
          }}
          variant={'outlined'}
          size={'small'}
          startIcon={<LanguageIcon />}
          className="btn-r"
        >
          Strona wydarzenia
        </Button>
        <Button
          onClick={() => onCancel()}
          variant={'outlined'}
          size={'small'}
          className="btn-r"
        >
          Zamknij
        </Button>
      </DialogActionsStyled>
    </>
  );
};
export default EventViewModal;
