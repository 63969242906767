import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { Alert, Box, Button, Grid } from '@mui/material';
import { useNotification } from '../../providers/notification/notification.context';
import http from '../../api/http';
import Layout from '../../components/Layout/Layout';
import 'moment/locale/pl';
import { displayDate, displayTime, getEventStatusLabel } from '../../utils';
import { PublicTrainer } from '../../types/PublicTrainer';
import { PublicEvent } from '../../types/PublicEvent';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import { useAuth } from '../../providers/auth/auth.context';
import useCreateBooking from '../../hooks/use-create-booking';
import { InfoBox, PageHeader } from '../../styles/general';
import ErrorPage from '../ErrorPage/ErrorPage';

const EventPage = () => {
  const params = useParams();
  const [event, setEvent] = useState<PublicEvent>();
  const [trainer, setTrainer] = useState<PublicTrainer>();
  const [isApiError, setIsApiError] = useState(false);
  const { setLoading } = useNotification();
  const { token } = useAuth();
  const createBooking = useCreateBooking();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (params.eventUid) {
      loadEvent(params.eventUid);
    }
  }, [params.eventUid]);

  const loadEvent = (eventUid: string) => {
    if (!eventUid) {
      return false;
    }
    setLoading(true);
    setIsApiError(false);
    http()
      .get(`/events/${eventUid}`, { params: { withTrainer: true } })
      .then(({ data: eventData }) => {
        setEvent(eventData.event);
        setTrainer(eventData.trainer);
      })
      .catch(() => {
        setIsApiError(true);
      })
      .finally(() => setLoading(false));
  };

  const onLoginRedirect = () => {
    navigate(`/login?next=${location.pathname}${location.search}`);
  };

  const getAvailableParticipantsAmount = () => {
    if (event) {
      return event.maxParticipantsAmount - event.participantsAmount;
    }
    return 0;
  };

  const isPossibleToBook = () => {
    if (event) {
      return (
        getAvailableParticipantsAmount() > 0 && event.status === 'not_started'
      );
    }
    return false;
  };

  if (isApiError) {
    return (
      <Layout>
        <ErrorPage withLogo={false} />
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeader>{event?.name}</PageHeader>

      {event && ['in_progress', 'finished'].includes(event.status) && (
        <Alert
          color={event.status === 'finished' ? 'error' : 'info'}
          className="mb20"
        >
          Trening {getEventStatusLabel(event.status)}
        </Alert>
      )}

      {event &&
        getAvailableParticipantsAmount() <= 0 &&
        event.status === 'not_started' && (
          <Alert color={'warning'} className="mb20">
            Brak wolnych miejsc
          </Alert>
        )}

      {trainer && (
        <Box className="mb20">
          <Link to={`/t/${trainer.slug}`}>Wróć do kalendarza trenera</Link>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Grid container spacing={2} className="mb20">
            <Grid item xs={12} sm={6}>
              <InfoBox>
                <CalendarMonthIcon fontSize={'small'} />{' '}
                <span>
                  Kiedy: {displayDate(event?.startTimestamp)}{' '}
                  {displayTime(event?.startTimestamp)}
                </span>
              </InfoBox>
              <InfoBox>
                <AccessTimeIcon fontSize={'small'} />{' '}
                <span>Czas trwania: {event?.time} min.</span>
              </InfoBox>
              <InfoBox>
                <AttachMoneyIcon fontSize={'small'} />{' '}
                <span>Cena: {event?.price} zł</span>
              </InfoBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoBox>
                <LocationOnIcon fontSize={'small'} />
                <span>Gdzie: {event?.location}</span>
              </InfoBox>
              {event && event.maxParticipantsAmount > 1 && (
                <InfoBox>
                  <GroupsIcon fontSize={'small'} /> <span>Zajęcia grupowe</span>
                </InfoBox>
              )}
              {event && event.maxParticipantsAmount === 1 && (
                <InfoBox>
                  <PersonIcon fontSize={'small'} />
                  <span>Zajęcia indywidualne</span>
                </InfoBox>
              )}
              {isPossibleToBook() && (
                <InfoBox>
                  <PersonPinIcon fontSize={'small'} />
                  <span>
                    Wolnych miejsc: {getAvailableParticipantsAmount()}
                  </span>
                </InfoBox>
              )}
            </Grid>
          </Grid>

          {isPossibleToBook() && (
            <Box className="mb20">
              {token && event && getAvailableParticipantsAmount() > 0 && (
                <Button
                  size={'medium'}
                  variant={'contained'}
                  onClick={() => {
                    createBooking(event?.uid, (reload) => {
                      if (reload && params?.eventUid) {
                        loadEvent(params.eventUid);
                      }
                    });
                  }}
                  startIcon={<EventAvailableIcon />}
                  className="btn-r"
                >
                  Zapisz się na zajęcia
                </Button>
              )}
              {!token && getAvailableParticipantsAmount() > 0 && (
                <Button
                  size={'small'}
                  variant={'outlined'}
                  onClick={() => onLoginRedirect()}
                  className="btn-r"
                >
                  Zaloguj się, aby się zapisać
                </Button>
              )}
            </Box>
          )}

          <p>{event?.description}</p>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={3}>
          {trainer && <TrainerCard trainer={trainer} />}
        </Grid>
      </Grid>
    </Layout>
  );
};
export default EventPage;
