import React, { useState } from 'react';
import { ModalContext } from './modal.context';
import { ModalParams } from '../../modals/modals.types';
import ModalContainer from '../../modals/ModalContainer';

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalParams, setModalParams] = useState<ModalParams>();

  const openModal = (params: any) => {
    setModalParams(params);
    setIsModalVisible(true);
  };

  const onModalCancel = (data?: any) => {
    modalParams?.onCancel && modalParams?.onCancel(data);
    setIsModalVisible(false);
  };

  const onModalChange = (data?: any) => {
    modalParams?.onChange && modalParams?.onChange(data);
    setIsModalVisible(false);
  };

  const value = { openModal };

  return (
    <>
      <ModalContext.Provider value={value}>
        <>{children}</>
        {modalParams?.type && (
          <ModalContainer
            isModalVisible={isModalVisible}
            type={modalParams?.type}
            onCancel={onModalCancel}
            onChange={onModalChange}
            params={modalParams?.params}
          />
        )}
      </ModalContext.Provider>
    </>
  );
};
export default ModalProvider;
