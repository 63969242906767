export type Message = {
  visible: boolean;
  content: string;
  type: MessageType;
};

export type MessageType = 'success' | 'error' | 'info' | 'warning';

export const initialMessage = {
  visible: false,
  content: '',
  type: 'info' as MessageType,
};
