import React, { useRef, useState } from 'react';
import {
  Button,
  FormGroup,
  FormLabel,
  Grid,
  Link,
  TextField,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Layout from '../../components/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../providers/notification/notification.context';
import http from '../../api/http';

const LoginPage = () => {
  const passwordRef = useRef<any>();
  const codeRef = useRef<any>();
  const emailRef = useRef<any>();
  const { setLoading, showMessage } = useNotification();
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  const onSendCodeClick = () => {
    const email = emailRef.current.value;

    setLoading(true);
    http()
      .post('/auth/remind', { email })
      .then(() => {
        showMessage(
          'Jeżeli podany adres e-mail istnieje w naszej bazie to wysłaliśmy na niego wiadomość z kodem do zresetowania hasła.',
          'success'
        );
        setUserEmail(email);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          showMessage(error.response.data.message, 'error');
        } else {
          showMessage(
            'Wystąpił błąd podczas wysyłania kodu. Sprawdź wpisane dane i spróbuj ponownie za chwilę.',
            'error'
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const onSetNewPassword = () => {
    const password = passwordRef.current.value;
    const code = codeRef.current.value;

    setLoading(true);
    http()
      .post('/auth/reset', {
        email: userEmail,
        password,
        code,
      })
      .then(() => {
        showMessage(
          'Nowe hasło zostało ustawione, możesz się teraz zalogować',
          'success'
        );
        navigate('/login');
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          showMessage(error.response.data.message, 'error');
        } else {
          showMessage(
            'Wystąpił błąd podczas ustawiania nowego hasła. Sprawdź wpisane dane i spróbuj ponownie za chwilę',
            'error'
          );
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12} sx={{ margin: '0 auto' }}>
          <h2 className="mb20 text-center">Resetowanie hasła</h2>

          {!userEmail && (
            <>
              <p className="mb20">
                Podaj adres e-mail przypisany do Twojego konta. Prześlemy na
                niego kod dzięki któremu będziesz mógł ustawić nowe hasło.
              </p>

              <FormGroup className="mb20">
                <FormLabel>Adres e-mail</FormLabel>
                <TextField
                  inputRef={emailRef}
                  variant={'outlined'}
                  type={'email'}
                  size={'small'}
                  required={true}
                  inputProps={{ 'data-testid': 'inpEmail' }}
                />
              </FormGroup>

              <div className="text-center mb20">
                <Button
                  onClick={onSendCodeClick}
                  variant={'contained'}
                  size={'large'}
                  type={'submit'}
                  data-testid={'btnSendCode'}
                  className="btn-r"
                >
                  Wyślij kod
                </Button>
              </div>
            </>
          )}

          {userEmail && (
            <>
              <p className="mb20">
                Twój adres e-mail:
                <br />
                <strong>{userEmail}</strong>
              </p>

              <FormGroup className="mb20">
                <FormLabel>Podaj kod</FormLabel>
                <TextField
                  inputRef={codeRef}
                  variant={'outlined'}
                  type={'text'}
                  size={'small'}
                  required={true}
                  inputProps={{ 'data-testid': 'inpCode' }}
                />
              </FormGroup>

              <FormGroup className="mb20">
                <FormLabel>Ustaw nowe hasło</FormLabel>
                <TextField
                  inputRef={passwordRef}
                  variant={'outlined'}
                  type={'password'}
                  size={'small'}
                  required={true}
                  inputProps={{ 'data-testid': 'inpPassword' }}
                />
              </FormGroup>

              <div className="text-center mb20">
                <Button
                  onClick={onSetNewPassword}
                  variant={'contained'}
                  size={'large'}
                  type={'submit'}
                  data-testid={'btnSendCode'}
                  className="btn-r"
                >
                  Ustaw nowe hasło
                </Button>
              </div>

              <p className="mb10">
                Wiadomość z kodem nie dotarła? Sprawdź podany adres e-mail i
                spróbuj jeszcze raz.
              </p>
              <div className="text-center">
                <Button
                  variant={'outlined'}
                  size={'small'}
                  onClick={() => setUserEmail('')}
                >
                  Ponów resetowanie hasła
                </Button>
              </div>
            </>
          )}

          <Divider sx={{ mt: '30px', mb: '30px' }} />

          <div className={'text-center'}>
            <Link href={'/login'} data-testid={'linkRegister'}>
              Zaloguj się!
            </Link>{' '}
            |{' '}
            <Link href={'/register'} data-testid={'linkRegister'}>
              Zarejestruj się!
            </Link>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};
export default LoginPage;
