import React, { createContext } from 'react';
import { ModalParams } from '../../modals/modals.types';

type ModalContextType = {
  openModal: (params: ModalParams) => any;
};

const initialModalContext = {
  openModal: () => {},
};

export const ModalContext =
  createContext<ModalContextType>(initialModalContext);

export const useModal = () => React.useContext(ModalContext);
