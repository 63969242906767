import React from 'react';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import { DialogActionsStyled } from '../modal.styles';

const QuestionModal = ({
  header,
  content,
  cancelLabel = 'Anuluj',
  confirmLabel = 'OK',
  onCancel,
  onChange,
}: {
  header: string;
  content: string;
  cancelLabel?: string;
  confirmLabel?: string;
  onCancel: () => void;
  onChange: (data: any) => void;
}) => {
  return (
    <>
      <DialogTitle className="DialogTitlePR">{header}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActionsStyled>
        <Button onClick={() => onCancel()} variant={'outlined'}>
          {cancelLabel}
        </Button>
        <Button onClick={onChange} variant={'contained'} color={'primary'}>
          {confirmLabel}
        </Button>
      </DialogActionsStyled>
    </>
  );
};
export default QuestionModal;
