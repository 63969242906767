import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { Color } from '../../../styles/colors';
import {
  displayDate,
  displayDayMonth,
  displayTime,
  displayWeekDay,
} from '../../../utils';
import { InfoBox } from '../../../styles/general';
import GroupsIcon from '@mui/icons-material/Groups';
import { ButtonNext, ButtonPrev } from '../TrainerCalendar.styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import moment from 'moment/moment';
import { dateFormat } from '../../../config/config';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import http from '../../../api/http';
import { PublicEvent } from '../../../types/PublicEvent';
import { useNotification } from '../../../providers/notification/notification.context';
import { useNavigate } from 'react-router-dom';

const daysAmount = 4;

const CalendarEvents = ({ trainerSlug }: { trainerSlug: string }) => {
  const [events, setEvents] = useState<PublicEvent[]>([]);
  const { setLoading, showMessage } = useNotification();
  const navigate = useNavigate();
  const [days, setDays] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>(
    moment().format(dateFormat)
  );
  const [endDate, setEndDate] = useState<string>(
    moment()
      .add(`${daysAmount - 1}`, 'days')
      .format(dateFormat)
  );

  useEffect(() => {
    if (trainerSlug) {
      loadTrainerEvents();
    }
  }, [trainerSlug]);

  useEffect(() => {
    reloadWeekDays();
  }, [startDate, endDate]);

  const reloadWeekDays = () => {
    const newDays = [];
    for (let i = 0; i <= moment(endDate).diff(moment(startDate), 'days'); i++) {
      newDays.push(moment(startDate).add(i, 'days').format(dateFormat));
    }
    setDays(newDays);
    if (trainerSlug) {
      loadTrainerEvents();
    }
  };

  const getEventsForDay = (day: string) =>
    events.filter((ev) => ev.startDate === day);

  const onPrevDays = () => {
    setStartDate(
      moment(startDate).subtract(daysAmount, 'days').format(dateFormat)
    );
    setEndDate(moment(endDate).subtract(daysAmount, 'days').format(dateFormat));
  };

  const onNextDays = () => {
    setStartDate(moment(startDate).add(daysAmount, 'days').format(dateFormat));
    setEndDate(moment(endDate).add(daysAmount, 'days').format(dateFormat));
  };

  const loadTrainerEvents = () => {
    setLoading(true);
    // setIsApiError(false);
    http()
      .get(`/trainer/${trainerSlug}/events/`, {
        params: { startDate, endDate },
      })
      .then(({ data: eventsData }) => {
        setEvents(eventsData);
      })
      .catch(() => {
        // setIsApiError(true);
        showMessage(
          'Nie można załadować wydarzeń, dla wybranego przedziału dat',
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  const onEventView = (event: PublicEvent) =>
    navigate(`/e/${event.slug}/${event.uid}`);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        {moment(startDate).isAfter(moment(), 'day') && (
          <ButtonPrev
            variant={'contained'}
            color={'primary'}
            size={'small'}
            onClick={() => onPrevDays()}
          >
            <ChevronLeftIcon />
          </ButtonPrev>
        )}{' '}
        <Box component={'span'} sx={{ margin: '0 10px', fontWeight: 600 }}>
          {displayDate(startDate)} - {displayDate(endDate)}
        </Box>
        <ButtonNext
          variant={'contained'}
          color={'primary'}
          size={'small'}
          onClick={() => onNextDays()}
        >
          <ChevronRightIcon />
        </ButtonNext>
      </Box>

      <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
        <Button
          variant={'outlined'}
          size={'small'}
          startIcon={<ReplayIcon />}
          sx={{ borderRadius: '50px', fontSize: '12px' }}
          onClick={() => loadTrainerEvents()}
        >
          Odśwież
        </Button>
      </Box>

      <Grid container spacing={2} className="mb20">
        {days.map((day) => {
          return (
            <Grid item key={day} xs={12} sm={6} md={3} lg={3}>
              <Card sx={{ fontSize: '14px' }} variant={'outlined'}>
                <Box
                  sx={{
                    backgroundColor: Color.lightGrey,
                    padding: '10px 15px',
                    fontWeight: 600,
                    textAlign: 'center',
                  }}
                >
                  {displayDayMonth(day)}
                  <br />
                  {displayWeekDay(day)}
                </Box>
                <Box sx={{ padding: '10px' }}>
                  {getEventsForDay(day).length === 0 && (
                    <Box sx={{ color: Color.grey }}>
                      Brak dostępnych terminów
                    </Box>
                  )}
                  {getEventsForDay(day).length > 0 &&
                    getEventsForDay(day).map((ev) => {
                      return (
                        <Card
                          key={ev.uid}
                          sx={{
                            cursor: 'pointer',
                            padding: '10px 15px',
                            marginBottom: '10px',
                            textDecoration:
                              ev.status === 'not_started'
                                ? undefined
                                : 'line-through',
                          }}
                          onClick={() => {
                            onEventView(ev);
                          }}
                        >
                          <strong>{ev.name}</strong>
                          <p>
                            {ev.startTime} - {displayTime(ev.endTimestamp)}
                          </p>
                          {ev.maxParticipantsAmount > 1 && (
                            <InfoBox>
                              <GroupsIcon fontSize={'small'} />
                              <span>
                                Miejsc:{' '}
                                {ev.maxParticipantsAmount -
                                  ev.participantsAmount}
                              </span>
                            </InfoBox>
                          )}
                        </Card>
                      );
                    })}
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default CalendarEvents;
