export const marketplaceUrl = process.env.REACT_APP_MARKETPLACE_URL || '';
export const twojtrenerClientUrl =
  process.env.REACT_APP_TWOJTRENER_CLIENT_URL || '';
export const googleClientId =
  '333214425884-3rdpiq2f757m89lo1h0okhjat20daajo.apps.googleusercontent.com';
export const FBClientId = '724270462569502';
export const FBLoginRedirectUri = `${twojtrenerClientUrl}/login/facebook`;
export const tokenCookieName = 'tt-token';
export const authNextCookieName = 'tt-auth-next';
export const dateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'DD.MM.YYYY';
export const cookieDomain =
  process.env.NODE_ENV === 'production' ? 'twojtrener.pl' : 'localhost';
