export const Color = {
  main: '#a282e1',
  light: '#b190fc',
  dark: '#705793',
  white: '#fff',
  black: '#252525',
  darkGrey: '#5d5d5d',
  grey: '#949494',
  lightGrey: '#f1f1f1',
  lightGrey2: '#f8f8f8',
  veryLightGrey: '#efefef',
  red: '#ee6161',
  green: '#61ee89',
  blue: '#6189ee',
  eventWithClient: '#319ead',
  eventWithClientDark: '#23707a',
  finishedEventWithoutClient: '#979797',
  finishedEventWithoutClientDark: '#646464',
  menuColor: '#0000008c',
  menuHoverColor: '#000000b3',

  ttViolet: '#6b1ffe',
  ttVioletLight: '#b892ff',
  ttVioletDark: '#4b16bb',
};
