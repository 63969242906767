import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../api/http';
import { Box, Button } from '@mui/material';
import { useNotification } from '../../providers/notification/notification.context';
import { useAuth } from '../../providers/auth/auth.context';
import Layout from '../../components/Layout/Layout';

const ConfirmEmailPage = () => {
  const { handleToken } = useAuth();
  const { showMessage, setLoading } = useNotification();
  const { hash } = useParams();
  const [hashCode, setHashCode] = useState<string>();

  useEffect(() => {
    if (hash) {
      setHashCode(hash);
    }
  }, [hash]);

  useEffect(() => {
    if (hashCode) {
      onConfirm();
    }
  }, [hashCode]);

  const onConfirm = () => {
    setLoading(true);
    http()
      .post('/auth/confirm-email/', { hash })
      .then(({ data }) => {
        if (data && data.token) {
          showMessage('Twoje konto zostało aktywowane!', 'success');
          handleToken(data.token);
        }
      })
      .catch(() => {
        showMessage(
          'Wystąpił błąd podczas aktywacji konta, spróbuj ponownie za kilka chwil',
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout>
      <Box sx={{ textAlign: 'center' }}>
        <Button onClick={onConfirm} variant={'contained'} size={'large'}>
          Aktywuj konto
        </Button>
      </Box>
    </Layout>
  );
};
export default ConfirmEmailPage;
