export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  type: number;
};

export const initialUser: User = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  type: 10,
};
