import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import { Box, Button } from '@mui/material';
import { useNotification } from '../../providers/notification/notification.context';
import http from '../../api/http';
import { useParams } from 'react-router-dom';

const CancelEventPage = () => {
  const { setLoading, showMessage } = useNotification();
  const params = useParams();

  useEffect(() => {
    if (params.eventClientId && params.eventClientHash) {
      onEventCancel(params.eventClientId, params.eventClientHash);
    }
  }, []);

  const onEventCancel = (eventClientId: string, eventClientHash: string) => {
    if (window.confirm('Czy na pewno chcesz anulować swoją obecność?')) {
      setLoading(true);
      http()
        .get(`/client/events/cancel/${eventClientId}/${eventClientHash}`)
        .then(({ data }) => {
          if (data?.status === 'err' && data?.msg) {
            showMessage(data.msg, 'error');
          } else if (data?.status === 'ok' && data?.msg) {
            showMessage(data.msg, 'success');
          }
        })
        .catch((err) => {
          showMessage(
            err?.response?.data?.msg || 'Nie można anulować tego wydarzenia',
            'error'
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Layout>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant={'contained'}
          size={'large'}
          onClick={() =>
            onEventCancel(
              params?.eventClientId || '',
              params.eventClientHash || ''
            )
          }
        >
          Anuluj trening
        </Button>
      </Box>
    </Layout>
  );
};
export default CancelEventPage;
