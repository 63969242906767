import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useNotification } from '../../providers/notification/notification.context';
import http from '../../api/http';
import Layout from '../../components/Layout/Layout';
import 'moment/locale/pl';
import { useModal } from '../../providers/modal/modal.context';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import { PublicTrainer } from '../../types/PublicTrainer';
import { PageHeader } from '../../styles/general';
import ErrorPage from '../ErrorPage/ErrorPage';
import CalendarSlots from './components/CalendarSlots';
import CalendarEvents from './components/CalendarEvents';
import useTrainer from '../../hooks/use-trainer';

const TrainerCalendar = () => {
  const params = useParams();
  const trainer: PublicTrainer | undefined = useTrainer(params.trainerSlug);
  const { setLoading, showMessage } = useNotification();
  const { openModal } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [trainerSlug, setTrainerSlug] = useState<string>();
  const [isApiError, setIsApiError] = useState(false);
  const [showSlots, setShowSlots] = useState(false);

  useEffect(() => {
    const openMessageModal = !!searchParams.get('message');
    if (openMessageModal && trainer) {
      onOpenMessageModal();
      setSearchParams();
    }
  }, [searchParams, trainer]);

  useEffect(() => {
    setTrainerSlug(params.trainerSlug);
  }, [params.trainerSlug]);

  const onOpenMessageModal = () => {
    openModal({
      type: 'message_modal',
      params: {
        trainer,
      },
      onCancel: (isChange: boolean) => false,
    });
  };

  if (isApiError) {
    return (
      <Layout>
        <ErrorPage withLogo={false} />
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeader>Kalendarz treningów</PageHeader>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          {trainer && (
            <TrainerCard trainer={trainer} disableCalendarButton={true} />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9}>
          {showSlots && trainerSlug && (
            <CalendarSlots trainerSlug={trainerSlug} />
          )}
          {!showSlots && trainerSlug && (
            <CalendarEvents trainerSlug={trainerSlug} />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
export default TrainerCalendar;
